import React from "react";
import MyButton from "../UI/Btn/MyButton";
import {useNavigate} from "react-router-dom";

const Item=(props)=>{

    const router = useNavigate();

    return(
        <div className="item item2 -hidden">
            <img className="item__image" src={"/images/"+props.image} alt={props.alt} />
            <p className="item__title">{props.title}</p>
            <p className="item__about break cancel">{props.desc}</p>
            <p className="item__about item__about2 break cancel">{props.more}</p>
            <div className="item__costdiv" onClick={()=>{router(props.route)}}>
                <p className="item__more item__more1">More</p>
                <MyButton>From {props.price}$</MyButton>
            </div>
        </div>
    )
}

export default Item;