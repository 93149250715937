import React, {useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const SiteDevPage=()=>{

    useEffect(()=>{
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
        window.scrollTo(0, 0);
      },[])

    const router = useNavigate();

    return(
        <div className="page">
            <Helmet>
                <meta name="description" content="Development stages and explanation of the development process itself."/>
                <title>Development of an Individual Turnkey Website(From 300$)</title>
            </Helmet>
            <p className="page__title -hidden animation1">Development</p>
            <img className="page__img -hidden animation1" src="images/dev.webp" alt="development" />
            <div className="page-top">
                <p className="page-top__p -hidden animation2">Description</p>
            </div>
            <p className="page__descrp break cancel -hidden animation2">
            We communicate with you, discuss the site itself and its functionality.
            Once the budget and the website are approved, we start working. First, you decide which type of design suits you best,
            basically, it's a template design or a custom design. Template design is a design made according to a ready-made template and its features:<br/>
            -It is cheaper<br/>
            -It's faster to get started.<br/>
            A design by a designer is an individual design developed specifically for you, its features:<br/>
            -A more interesting and individual style<br/>
            -It adds up to 4 days to the development time.<br/>
            When you have chosen the type of design, we proceed to the creation of the layout (Layout is the appearance of the future site).
            After you approve the layout, we proceed to the layout stage (layout is the transfer of the layout to html and css).
            We make adaptive layout, that is, your site will look good on different devices with different displays.
            Next, we carry out front-end and, if necessary, back-end development, and proceed to the final stage of development.
            We add the necessary settings, <a className="linkintext" onClick={()=>{router("/seo")}} >do SEO</a>,
            <a className="linkintext" onClick={()=>{router("/analytics")}}> connecting analytics</a>,
            we add the necessary functionality and fully test the site on different devices in different browsers to improve the user's experience
            the user's experience with the site.<a className="linkintext" onClick={()=>{router("/content")}}> After that, let's move on to the content of the site.</a>
            </p>
        </div>
    )
}

export default SiteDevPage;