import React, {useEffect} from "react";
import PageItem from "../../components/PageItem";

const SiteContentPage=()=>{

    useEffect(()=>{
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
        window.scrollTo(0, 0);
      },[])

    return(
        <PageItem type="0" pagedescrp="Filling a website with content is a very important part of website development, here is some information about it." title="Creating Content for Your Website(0$)"
         title2="Content" image="content.webp" alt="content"
          desc="Filling a website with content is no less important than development.
          You need to fill your site with content, understanding the logic of search engines and choosing the right words.
          You can fill the site yourself or entrust it to us."></PageItem>
    )
}

export default SiteContentPage;