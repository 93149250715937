import React from "react";
import cl from "./MyModal.module.css" 

const MyModal = ({children, visible, setVisible, no})=>{

    const rootClasses = [cl.myModal]
    if(visible){
        rootClasses.push(cl.active);
    }

    return(
        <div className={rootClasses.join(" ")} onClick={()=>{if(no==undefined) setVisible(false)}}>
            <div className={cl.myModalContent} onClick={(e)=> {e.stopPropagation();}}>
                <div className={cl.myModalFlexContent}>
                    {children}
                </div>      
            </div>
        </div>
    );
};

export default MyModal;