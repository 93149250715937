import React, {useEffect} from "react";
import PageItem from "../../components/PageItem";

const YourSite=()=>{

    useEffect(()=>{
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
        window.scrollTo(0, 0);
      },[])

    return(
        <PageItem type="1" pagedescrp="Full turnkey Your website development. We guarantee quality and professionalism." title="Creating Your Turnkey Website at an Affordable Price (from $450)"
         title2="Your site" image="ownsite.webp" alt="your site"
          desc="If you have any interesting suggestions for creating websites, you can always contact us by phone.
        It can be a forum, blog, etc. The deadline is discussed after determining the type and complexity of the site."></PageItem>
    )
}

export default YourSite;