import React, {useEffect} from "react";
import PageItem from "../../components/PageItem";

const SiteSuppPage=()=>{

    useEffect(()=>{
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
        window.scrollTo(0, 0);
      },[])

    return(
        <PageItem type="0" pagedescrp="Supporting your website after the job is done." title="Our Support of Your Website After the Work is Done(0$)"
         title2="Support" image="support.webp" alt="support"
          desc="We provide support both during the work and after completion.
          We answer all your questions and explain how, what, why. When the work is done, you can always contact us with questions,
          or with suggestions for new projects or improvements, we will definitely listen to you and answer your questions."></PageItem>
    )
}

export default SiteSuppPage;