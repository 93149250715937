import React, {useEffect} from "react";
import PageItem from "../../components/PageItem";

const SiteSeoPage=()=>{

    useEffect(()=>{
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
        window.scrollTo(0, 0);
      },[])

    return(
        <PageItem type="0" pagedescrp="How we do SEO and why." title="Make SEO On Your Website(0$)"
         title2="SEO" image="seo.webp" alt="seo"
          desc="Every website needs SEO. We do it for you. Basically,
          it consists in connecting meta tags and adding keywords to the title, description, and content of the site.
          This is done to increase the number of visits to the site and its position in search engines relative to competitors."></PageItem>
    )
}

export default SiteSeoPage;