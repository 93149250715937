import React, {useEffect} from "react";
import PageItem from "../../components/PageItem";

const LandingPage=()=>{

    useEffect(()=>{
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
        window.scrollTo(0, 0);
      },[])

    return(
        <PageItem type="1" pagedescrp="Full turnkey Landing website development. We guarantee quality and professionalism." title="Creation of a Turnkey Landing Site at an Affordable Price (from $300)" title2="Landing" image="landing.webp"
         alt="landing" desc="A landing page is a website that usually consists of 1-3 pages. Landing is an advertisement for one product or service in order to increase
         the demand for this product/service. The term for creating such a website is usually up to 1 months."></PageItem>
    )
}

export default LandingPage;