import React, {useEffect} from "react";
import PageItem from "../../components/PageItem";

const ShopPage=()=>{

    useEffect(()=>{
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
        window.scrollTo(0, 0);
      },[])

    return(
        <PageItem type="1" pagedescrp="Full turnkey Online shop development. We guarantee quality and professionalism." title="Creation of a Turnkey Online Store at a Price (from $2500)" title2="Online store" image="shop.webp" alt="shop"
         desc="The purpose of the online store is to sell goods online, we create high-quality stores with security systems and modern design.
         Also, this is a large project that has a lot of functionality beyond the store itself,
         for example, SMS messaging to increase the number of customers, an automatic payment system,
         registration, and much more. The term for creating such a website is usually up to 2 months."></PageItem>
    )
}

export default ShopPage;