import React, {useEffect} from "react";
import PageItem from "../../components/PageItem";

const PortfolioPage=()=>{

    useEffect(()=>{
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
        window.scrollTo(0, 0);
      },[])

    return(
        <PageItem type="1" pagedescrp="Full turnkey Portfolio website development. We guarantee quality and professionalism." title="Creation of a Turnkey Portfolio Website at an Affordable Price (from $300)" title2="Portfolio website" image="portfolio.webp" alt="portfolio"
         desc="A portfolio site is a website that aims to show your work and skills to people. It can be photos, paintings, scripts, etc.
         The term for creating such a website is usually up to 2 weeks."></PageItem>
    )
}

export default PortfolioPage;