import React, {useEffect, useState} from "react";
import MyModal from "../UI/MyModal/MyModal";
import { Helmet } from "react-helmet";
import Item from "../components/Item";
import Item2 from "../components/Item2";
import autosize from "autosize";
import MyButtonSent from "../UI/BtnSent/MyButton";
import axios from "axios";

const Main=()=>{

    const [width, setWidth] = useState(window.innerWidth);
    const [visible, setVisible] = useState(false);
    const [modalConfirm, setModalConfirm] = useState(false);
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");


    useEffect(()=>{
        autosize(document.querySelectorAll('.content__textarea'));
    },[email, message])

    useEffect(()=>{
        window.addEventListener("resize", ()=>{
            setWidth(window.innerWidth);
        }); 
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
        window.scrollTo(0, 0);
    },[])

    const PostMessage=(email, message)=>{
        axios.post("https://wishesite-server.netlify.app/.netlify/functions/index/message", {email:email, message:message}).then((res)=>{
            if(res.data.error!==undefined){
                console.log(res.data.error);
            }
            else{
                console.log(res.data);
            }
        });
    }

    return(
        <div className="main">
            <Helmet>
                <meta name="description" content="Full turnkey website development. We guarantee quality and professionalism."/>
                <title>Creation of a Turnkey Website at an Affordable Price (from $300)</title>
            </Helmet>
            <MyModal visible={visible} setVisible={setVisible}>{width}</MyModal>
            <MyModal visible={modalConfirm} setVisible={setModalConfirm}>The email was sent</MyModal>
            <div className="main-block1" id="block1">
                <div className="block1-top">
                    <p className="block1-top-p -hidden">Prices</p>
                </div>
                <div className="block1-middle block2-middle">
                    <Item title="Business card site" desc="With a business card website, you can provide the user with short, concise,
                         informative and visually appealing information about your business."
                         more="It is most suitable for private entrepreneurs and small businesses."
                         price="300" image="bscw.webp" alt="business-card"
                         route="/business-card"></Item>
                    <Item title="Portfolio website" desc="With a portfolio site, you can showcase your work to the user,
                         achievements and skills through an easy-to-use website." more="It is most suitable for designers, artists, photographers, and programmers."
                          price="300" image="portfolio.webp" alt="portfolio" route="/portfolio"></Item>
                    <Item title="Landing" desc="A landing page encourages the user to perform a certain action.
                         It can be a purchase of goods, ordering a service, and so on." 
                    more="It is most suitable for advertising a single product or service." price="300" image="landing.webp" alt="landing" route="/landing"></Item>
                    <Item title="Your site" desc="If you want to create a website
                         that is not on our list, you can always discuss this issue with us by email."
                     more="It is most suitable for everyone who wants to have their own interesting website." price="450" image="ownsite.webp" alt="custom" route="/custom"></Item>
                    <Item title="Corporate website" desc="A corporate website is a site that provides users with information about the company,
                         its products or services, mission, and contact information." more="It is most suitable for large companies and large-scale businesses." price="2000" image="corporate.webp" alt="corporate" route="/corporate"></Item>
                    <Item title="Online store" desc="An online store is a website that allows users to browse
                        select and purchase goods or services online." more="It is most suitable for entrepreneurs and people who want to start selling online." price="2500" image="shop.webp" alt="shop" route="/shop"></Item>
                </div>   
            </div>
            <div className="main-block2" id="block2">
                <div className="block1-top">
                    <p className="block1-top-p -hidden">Our services</p>
                </div>
                <div className="block1-middle">
                    <Item2 title="Development" image="dev.webp" alt="create website"
                     route="/dev" desc="Website development consists of several stages all of which we discuss with you and execute efficiently."></Item2>
                    <Item2 title="SEO" image="seo.webp"
                     alt="website seo" route="/seo" desc="We add the necessary meta tags and optimize your website for search engines which improves traffic to your website."></Item2>
                    <Item2 title="Content" image="content.webp" alt="website content"
                     route="/content" desc="After discussing with you we fill the site with content taking into account your wishes and our experience."></Item2>
                    <Item2 title="Publish" image="publish.webp" alt="website publish"
                     route="/publish" desc="We publish the website for hosting give you your account in the hosting service and explain how to use it."></Item2>
                    <Item2 title="Analytics" image="analytic.webp" alt="website analytics"
                     route="/analytics" desc="We connect analytics to the site so that you can monitor the success of the site."></Item2>
                    <Item2 title="Support" image="support.webp" alt="website support"
                     route="/support" desc="After our work is done we will provide you with basic support we do not disappear."></Item2>        
                </div>
                <div className="block1-featuresdiv">
                    <p className="block1-features -hidden">Our excellence</p>
                </div>
                <div className="block1-last">
                    <ul className="block1-last__list">
                        <li className="block1-last__list-item -hidden">Affordable prices.</li>      
                        <li className="block1-last__list-item -hidden">We always do our work on time.</li>
                        <li className="block1-last__list-item -hidden">All services are included in the price of the site.</li>  
                        <li className="block1-last__list-item -hidden">Opportunity to discuss all the details of website development.</li>
                    </ul>
                </div>
            </div>
            <div className="main-block3" id="block3">
                <div className="block1-top tel2">
                    <p className="block1-top-p block3-top-p tel5 -hidden">Leave message here:</p>
                </div>
                <div className="sendemail">
                    <div className="textarea__group">
                        <textarea id="email" className="content__textarea" rows="1" 
                        placeholder="Your email:" value={email} 
                        autoComplete="off" onChange={(event)=>{
                            setEmail(event.target.value);
                        }}/>
                        <label htmlFor="email"  className="content__label">Your email:</label>
                    </div>
                    <div className="textarea__group">
                        <textarea id="message" className="content__textarea" rows="10" 
                        placeholder="Message:" value={message} 
                        autoComplete="off" onChange={(event)=>{
                            setMessage(event.target.value);
                        }}/>
                        <label htmlFor="message"  className="content__label">Message:</label>
                    </div>
                    <MyButtonSent onClick={()=>{
                        if(email.trim().length!==0){
                            setModalConfirm(true);
                            PostMessage(email, message);
                            setEmail("");
                            setMessage("");
                        }
                    }}>Send</MyButtonSent>
                </div>
                <div className="block1-top tel3">
                    <p className="block1-top-p block3-top-p -hidden">Or you can email us yourself:</p>
                </div>
                <div className="block1-top tel2">
                    <p className="block1-top-p block3-top-p tel5 tel6 -hidden">wishesite0@gmail.com</p>
                </div>
            </div>
        </div>
    )
}

export default Main;