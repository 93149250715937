import React, {useEffect} from "react";
import PageItem from "../../components/PageItem";

const SitePublishPage=()=>{

    useEffect(()=>{
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
        window.scrollTo(0, 0);
      },[])

    return(
        <PageItem type="0" pagedescrp="How we publish a website for hosting." title="Publishing Your Site on the Hosting Service(0$)"
         title2="Publish" image="publish.webp" alt="publish"
          desc="We discuss which hosting service you like best and choose the best one for you,
          we publish your website and make the necessary settings. After that, we give you the data from the admin panel and teach you how to use it."></PageItem>
    )
}

export default SitePublishPage;