import React, {useEffect} from "react";
import PageItem from "../../components/PageItem";

const BsCardPage=()=>{

    useEffect(()=>{
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
        window.scrollTo(0, 0);
      },[])

    return(
        <PageItem type="1" pagedescrp="Full turnkey Business card website development. We guarantee quality and professionalism." title="Creation of a Turnkey Business Card Website at an Affordable Price (from $300)" title2="Business card site" image="bscw.webp" alt="business card" desc="A business card site is a website that usually consists of 1-3 pages. Its main purpose is to provide the user with information about you,
        about your business, which can significantly increase the demand for your services. You can say it's like a plastic business card, only online.
        The term for creating such a website is usually up to 2 Weeks."></PageItem>
    )
}

export default BsCardPage;