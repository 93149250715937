import React, {useEffect} from "react";
import PageItem from "../../components/PageItem";

const SiteAnalyticsPage=()=>{

    useEffect(()=>{
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
        window.scrollTo(0, 0);
      },[])

    return(
        <PageItem type="0" pagedescrp="Analytics is very important for your website, here is some information about it." title="Connecting Analytics to Your Website(0$)"
         title2="Analytics" image="analytic.webp" alt="analytics"
          desc="We connect analytics to your site, which you can use to see
          how many requests there were to your website at a certain time, online users and much more."></PageItem>
    )
}

export default SiteAnalyticsPage;