import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Main from "../pages/Main";
import BsCardPage from "../pages/block1/BsCardPage";
import CorporatePage from "../pages/block1/CorporatePage";
import ShopPage from "../pages/block1/ShopPage";
import YourSite from "../pages/block1/YourSite";
import LandingPage from "../pages/block1/LandingPage";
import PortfolioPage from "../pages/block1/PortfolioPage";
import SiteAnalytics from "../pages/block2/SiteAnalytics";
import SiteSuppPage from "../pages/block2/SiteSuppPage";
import SiteContentPage from "../pages/block2/SiteContentPage";
import SiteDevPage from "../pages/block2/SiteDevPage";
import SitePublishPage from "../pages/block2/SitePublishPage";
import SiteSeoPage from "../pages/block2/SiteSeoPage";

const AppRouter=()=>{
    return(
        <Routes>
            <Route exact path="*" element={<Navigate to="/home"/>}/>
            <Route exact path="/business-card" element={<BsCardPage/>}/>
            <Route exact path="/portfolio" element={<PortfolioPage/>}/>
            <Route exact path="/landing" element={<LandingPage/>}/>
            <Route exact path="/custom" element={<YourSite/>}/>
            <Route exact path="/corporate" element={<CorporatePage/>}/>
            <Route exact path="/shop" element={<ShopPage/>}/>
            <Route exact path="/analytics" element={<SiteAnalytics/>}/>
            <Route exact path="/support" element={<SiteSuppPage/>}/>
            <Route exact path="/content" element={<SiteContentPage/>}/>
            <Route exact path="/dev" element={<SiteDevPage/>}/>
            <Route exact path="/publish" element={<SitePublishPage/>}/>
            <Route exact path="/seo" element={<SiteSeoPage/>}/>
            <Route exact path="/home" element={<Main/>}/>
        </Routes>
    )
}

export default AppRouter;