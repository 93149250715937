import React from "react";
import { Helmet } from "react-helmet";

const PageItem=(props)=>{

    return(
        <div className="page">
            <Helmet>
                <meta name="description" content={props.pagedescrp}/>
                <title>{props.title}</title>
            </Helmet>
            <p className="page__title -hidden animation1">{props.title2}</p>
            <img className="page__img -hidden animation1" src={"images/"+props.image} alt={props.alt} />
            <div className="page-top">
                <p className="page-top__p -hidden animation2">Description</p>
            </div>
            {props.type=="1"?<div>
                <p className="page__descrp break cancel -hidden animation2">
                {props.desc}</p>
                <br/>
                <p className="page__descrp page__descrpbtm"> *The price may vary depending on the number of pages, type of design, hosting service, and website functionality. All this is discussed before starting work.</p>             
            </div>
           :<p className="page__descrp break cancel -hidden animation2">
            {props.desc}
            </p>}
        </div>
    )
}

export default PageItem;