import React, {useEffect} from "react";
import PageItem from "../../components/PageItem";

const CorporatePage=()=>{

    useEffect(()=>{
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
        window.scrollTo(0, 0);
      },[])

    return(
        <PageItem type="1" pagedescrp="Full turnkey Corporate website development. We guarantee quality and professionalism." title="Creation of a Turnkey Corporate Website at a Price (from 2000$)"
         title2="Corporate website" image="corporate.webp" alt="corporate" desc="The purpose of a corporate website is to increase the recognition of your company and thereby increase the number of regular customers. A corporate website is much larger than a business card website and has much more features. Also, it is a large project that has a lot of functionality, for example: SMS-mailing to increase the number of customers, an automatic payment system, registration, and much more. The term for creating such a website is usually up to 2 months."></PageItem>
    )
}

export default CorporatePage;